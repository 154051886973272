// importing libraries
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// importing styles
import classes from './WorkflowTabs.module.css';
import Dropdown from '../../atoms/dropdown/Dropdown';
import CustomTabs from '../custom-tabs/CustomTabs';
import { AttachmentStatus, SovType, workflowTabs } from '../../../services/constants/constants';
import MultiSelectionDropdown from '../../atoms/multi-selection-dropdown/MultiSelectionDropdown';
import useExtraction from '../../../hooks/useExtraction';
import useComparison from '../../../hooks/useComparison';
import { areWeInsideIframe } from '../../../services/utils/Helper';

function WorkflowTabs() {
  const [tabValue, setTabValue] = React.useState(null);
  const [selectedDocument, setSelectedDocument] = React.useState({});
  const [selectedMultipleDocument, setSelectedMultipleDocument] = React.useState([]);
  const [selectedMultipleDocumentIds, setSelectedMultipleDocumentIds] = React.useState([]);
  const [documentOptions, setDocumentOptions] = React.useState([]);
  const [isMultiSelectionDropdownOpen, setIsMultiSelectionDropdownOpen] = React.useState(false);
  const {
    documents,
  } = useSelector((state) => state.applications.openedApplication);
  const documentId = useSelector((state) => state.extraction.selectedDocumentId);
  const documentIds = useSelector((state) => state.comparison.selectedDocumentIds);
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { resetSelectedDocumentId, resetIsDataSkipped } = useExtraction(applicationId, documentId);
  const { resetSelectedDocumentIds } = useComparison();

  useEffect(() => () => {
    // Call on unamount component to remove document id and document ids
    resetSelectedDocumentId();
    resetSelectedDocumentIds();
  }, []);

  const handleChange = (_, newValue) => {
    setTabValue(newValue);

    if (newValue === 0 && documentOptions.length > 0) {
      const docId = documentId === '' ? documentOptions[0].key : documentId;
      const route = `/home/applications/${applicationId}/extraction?docs=${docId}`;
      navigate(route);
    }

    if (newValue === 1 && documentOptions.length > 0 && documentIds) {
      let docIds = '';
      if (documentOptions.filter((item) => documentIds.includes(item.key)).length > 0) {
        docIds = documentOptions.filter((item) => documentIds.includes(item.key))
          .map((item) => item.key)
          .join(',');
      }
      const route = `/home/applications/${applicationId}/comparison?docs=${docIds}`;
      navigate(route);
    }

    if (newValue === 2 && documentOptions.length > 0) {
      const firstSovDoc = documentOptions
        .find((doc) => doc.classification_type.toLowerCase() === SovType.toLowerCase())?.key ?? '';
      const route = `/home/applications/${applicationId}/sov-extraction?docs=${firstSovDoc}`;
      navigate(route);
    }
  };

  useEffect(() => {
    if (location.pathname.toLowerCase().includes('extraction')) {
      setTabValue(0);
    }
    if (location.pathname.toLowerCase().includes('comparison')) {
      setTabValue(1);
    }
    if (location.pathname.toLowerCase().includes('sov-extraction')) {
      setTabValue(2);
    }
  }, []);

  useEffect(() => {
    if (documents) {
      const newOptions = documents
        .filter((doc) => doc.status?.toLowerCase() === AttachmentStatus.Extracted.toLowerCase())
        .map((doc) => (
          {
            key: doc.id,
            document_name: doc.document_name,
            value: doc.document_name,
            classification_type: doc.classification.classification_type,
            sub_classification_type: doc.classification.sub_classification_type,
            carrier_name: doc.carrier_name,
          }
        ));
      setDocumentOptions(newOptions);
      setSelectedDocument(documentId);
    }
  }, [documents]);

  useEffect(() => {
    if (tabValue === 0 && documentOptions.length > 0) {
      setSelectedMultipleDocument([]);
    }

    if (tabValue === 1 && documentOptions.length > 0 && documentIds.length > 0) {
      if (documentOptions.filter((item) => documentIds.includes(item.key)).length > 0) {
        const classifications = documentOptions.filter((doc) => documentIds.includes(doc.key))
          .map((obj) => obj.classification_type);
        setSelectedMultipleDocument(classifications);
      }
    }
    setSelectedMultipleDocumentIds(documentIds);
  }, [documentOptions, documentIds, documentId, tabValue]);

  useEffect(() => {
    if (documentId) {
      setSelectedDocument(documentId);
    }
  }, [documentId]);

  const handleSwitchDocument = (docId) => {
    const route = `/home/applications/${applicationId}/extraction?docs=${docId}`;
    resetIsDataSkipped();
    navigate(route);
    setSelectedDocument(docId);
  };

  const handleDocumentSelection = (id) => {
    if (selectedMultipleDocumentIds.includes(id)) {
      setSelectedMultipleDocumentIds((prevItems) => prevItems.filter((item) => item !== id));
    } else {
      setSelectedMultipleDocumentIds((prevItems) => [...prevItems, id]);
    }
  };

  useEffect(() => {
    const selectedOption = documentOptions.filter((item) => selectedMultipleDocumentIds
      .includes(item.key)).map((item) => item.classification_type);
    setSelectedMultipleDocument(selectedOption);
  }, [selectedMultipleDocumentIds]);

  useEffect(() => {
    if (!isMultiSelectionDropdownOpen && tabValue === 1) {
      setSelectedMultipleDocumentIds(documentIds);
      const classifications = documentOptions.filter((doc) => documentIds.includes(doc.key))
        .map((obj) => obj.classification_type);
      setSelectedMultipleDocument(classifications);
    }
  }, [isMultiSelectionDropdownOpen, documentIds]);

  const viewComparisonValidation = () => {
    const checkedDocuments = documentOptions.filter((option) => selectedMultipleDocumentIds
      .includes(option?.key));

    if (checkedDocuments.length >= 1 && checkedDocuments.length < 5) {
      return true;
    }
    return false;
  };

  const handleViewComparison = () => {
    const foundCheck = viewComparisonValidation();
    if (foundCheck) {
      const docIds = documentOptions.filter((item) => selectedMultipleDocumentIds
        .includes(item?.key)).map((item) => item.key)
        .join(',');
      const route = `/home/applications/${applicationId}/comparison?docs=${docIds}`;
      navigate(route);
      return true;
    }
    return false;
  };

  const getFilteredTabs = () => {
    const filteredTabs = workflowTabs.filter((tab) => {
      if (tab.label === 'Extraction') {
        if (!areWeInsideIframe()) return tab.shouldShow;
        return false;
      }

      if (tab.label === 'SoV - Extraction') {
        if (documentOptions.find((doc) => doc
          .classification_type.toLowerCase() === SovType.toLowerCase())) return tab.shouldShow;
        return false;
      }

      return tab.shouldShow;
    });

    return filteredTabs;
  };

  const getExtractionDropdownOptions = () => {
    if (location.pathname.toLowerCase().includes('sov-extraction')) {
      return documentOptions
        .filter((docu) => docu
          ?.classification_type?.toLowerCase() === SovType.toLowerCase())
        .map((doc) => ({ key: doc.key, value: doc.value }));
    }
    return documentOptions
      .filter((docu) => docu
        ?.classification_type?.toLowerCase() !== SovType.toLowerCase())
      .map((doc) => ({ key: doc.key, value: doc.value }));
  };

  return (
    <div className={classes.workflowTabsContainer}>

      <div className={classes.documentContainer}>

        <span className={`h5Regular ${classes.viewingLabelColor}`}>Viewing:</span>
        <span>
          {(tabValue === 0 || tabValue === 2)
            ? (
              <Dropdown
                options={getExtractionDropdownOptions()}
                defaultOption="Select Documents"
                selectedOption={documentOptions
                  .find((document) => document.key === selectedDocument)?.document_name}
                handleOptionClick={handleSwitchDocument}
                selectHeight="1.45rem"
                selectWidth="12.625rem"
                optionsWidth="15rem"
              />
            )
            : (
              <MultiSelectionDropdown
                options={documentOptions.filter((doc) => doc
                  ?.classification_type?.toLowerCase() !== SovType.toLowerCase())}
                defaultOption="Select Documents"
                selectedOption={selectedMultipleDocument?.join(', ')}
                selectTextColor="infoTextColor"
                handleOptionClick={handleDocumentSelection}
                selectHeight="1.45rem"
                selectWidth="12.625rem"
                optionsWidth="22.456rem"
                optionsHeight="10.563rem"
                handleViewComparison={handleViewComparison}
                setIsOpen={setIsMultiSelectionDropdownOpen}
                buttonText="View Comparison"
                selectedMultipleDocumentIds={selectedMultipleDocumentIds}
                isButtonDisabled={documentOptions?.filter((option) => selectedMultipleDocumentIds
                  .includes(option?.key)).length < 1
                  || documentOptions?.filter((option) => selectedMultipleDocumentIds
                    .includes(option?.key)).length > 4}
              />
            )}
        </span>

      </div>

      <CustomTabs
        value={tabValue}
        handleChange={handleChange}
        tabsOptions={getFilteredTabs()}
      />
    </div>
  );
}

export default WorkflowTabs;
